'use strict';

class SWAL {
	constructor() {}

	static show(data, type) {
		if(typeof data === 'object') {
			swal({
				title: data.message,
				type: type,
				allowOutsideClick: true,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: "Закрыть",
			});
		} else {
			swal({
				title: data,
				type: type,
				allowOutsideClick: true,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: "Закрыть",
			});
		}
		return true;
	}

	static showReload(data, type) {
		if(typeof data === 'object') {
			swal({
				title: data.message,
				type: type,
				allowOutsideClick: true,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: "Закрыть",
			}, function(){
				window.location.reload();
			});
		} else {
			swal({
				title: data,
				type: type,
				allowOutsideClick: true,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: "Закрыть",
			}, function(){
				window.location.reload();
			});
		}
		return true;
	}

	static success(data) {
		if(typeof data === 'object') {
			swal({
				title: data.message,
				type: "success",
				allowOutsideClick: true,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: "Закрыть",
			});
		} else if(typeof data === 'string') {
			swal({
				title: data,
				type: "success",
				allowOutsideClick: true,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: "Закрыть",
			});
		} else {
			swal({
				title: "Операция успешна.",
				type: type,
				allowOutsideClick: true,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: "Закрыть",
			});
		}
		return true;
	}

	static error(data) {
		if(typeof data === 'object') {
			swal({
				title: data.message,
				type: "error",
				allowOutsideClick: true,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: "Закрыть",
			});
		} else if(typeof data === 'string') {
			swal({
				title: data,
				type: "error",
				allowOutsideClick: true,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: "Закрыть",
			});
		} else {
			swal({
				title: "Неизвестная ошибка.",
				type: "error",
				allowOutsideClick: true,
				showConfirmButton: true,
				showCancelButton: false,
				closeOnConfirm: true,
				closeOnCancel: true,
				confirmButtonText: "Закрыть",
			});
		}
		return true;
	}

	static wait(data) {
		swal({
			title: data.title,
			text: data.text,
			confirmButton: false,
			closeOnConfirm: false,
			showConfirmButton: false,
			closeOnCancel: false,
			imageUrl: "/img/loader.svg"
		  })
	}
}

class Toastr {
	constructor() {}

	static success(data) {
		if(typeof data === 'object') {
			toastr.success(data.message);
		} else if(typeof data === 'string') {
			toastr.success(data);
		} else {
			toastr.success('Операция успешна');
		}
		return true;
	}

	static warning(data) {
		if(typeof data === 'object') {
			toastr.warning(data.message);
		} else if(typeof data === 'string') {
			toastr.warning(data);
		}
		return true;
	}

	static info(data) {
		if(typeof data === 'object') {
			toastr.info(data.message);
		} else if(typeof data === 'string') {
			toastr.info(data);
		}
		return true;
	}

	static error (data) {
		if(typeof data === 'object') {
			toastr.error(data.message);
		} else if(typeof data === 'string') {
			toastr.error(data);
		} else {
			toastr.error('Неизвестная ошибка');
		}
		return true;
	}

	static debug(data, type) {
		if(Cookie.get('debug') === 1) {
			if(typeof data === 'object') {
				toastr[type](data.message);
			} else if(typeof data === 'string') {
				toastr[type](data);
			} else {
				toastr[type]('Debug without message');
			}
		}
		return true;
	}
}